<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Activity</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Activity"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
            v-model="searchTerm"
          ></v-text-field>
        </v-col>
        <!-- <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.workChunkForm.openForm()"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Work Chunk</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="warning"
                  :to="{ name: 'module-workgroup' }"
                  v-on="on"
                >
                  <v-icon left>mdi-view-grid</v-icon>Work Groups
                </v-btn>
              </template>
              <span>View Work Groups</span>
            </v-tooltip>
          </v-row>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="work_chunks"
            no-data-text="No work chunks found"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                :to="{
                  name: 'module-workload-individual',
                  params: { chunkId: item.id },
                }"
                >{{ item.name }}</router-link
              >
            </template>

            <template v-slot:item.team_member="{ item }">
              <div v-if="item.team_member">
                {{ item.team_member.first_name }}
                {{ item.team_member.last_name }}
              </div>
              <div v-else style="color: #cccccc">VITAL HIKE</div>
            </template>

            <template v-slot:item.customer="{ item }">
              <router-link
                v-if="item.customer"
                :to="{
                  name: 'customer-profile-basic',
                  params: { customerId: item.customer.id },
                }"
                >{{ item.customer.name }}</router-link
              >
              <div v-if="item.is_internal == 1" style="color: #cccccc">
                VITAL HIKE
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.workChunkForm.openForm(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip> -->

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        depressed
                        color="red lighten-4 red--text"
                        v-on="on"
                        @click="openDelete(item)"
                      >
                        <v-icon x-small>mdi-archive</v-icon>
                      </v-btn>
                    </template>
                    <span>Archive</span>
                  </v-tooltip>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="deleteTask.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Activity</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteTask.task.title }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteTask.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <WorkChunkForm
      ref="workChunkForm"
      :groups="groups"
      :customers="customers"
      :teamMembers="teamMembers"
    />
  </div>
</template>

<script>
import WorkChunkForm from "/src/modules/workload/views/chunks/components/WorkChunkForm";

export default {
  components: {
    WorkChunkForm,
  },

  data() {
    return {
      datePicker: false,
      statuses: ["Open", "Closed", "Deleted"],
      types: ["Project", "Ad-hoc", "Unknown", "Contract", "Diary"],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Workload",
          disabled: true,
        },
        {
          text: "Activity",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Customer", value: "customer" },
        { text: "User", value: "team_member" },
        { text: "Actions", value: "actions", align: "right" },
      ],

      deleteTask: {
        dialog: false,
        task: {},
        loading: false,
      },

      archiveWorkloadDialog: false,
      archiveWorkloadLoading: false,
      archiveWorkloadData: null,
    };
  },

  computed: {
    work_chunks() {
      let work_chunks = this.$store.getters["workload/activity"];

      if (this.searchTerm !== "") {
        work_chunks = work_chunks.filter((c) => {
          const name = c.name.toLowerCase();
          const description = c.description ? c.description.toLowerCase() : "";
          const customer =
            c.customer !== null ? c.customer.name.toLowerCase() : "";
          const teamLeader =
            c.team_member !== null
              ? c.team_member.first_name.toLowerCase() +
                " " +
                c.team_member.last_name.toLowerCase()
              : "";

          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            description.includes(this.searchTerm.toLowerCase()) ||
            customer.includes(this.searchTerm.toLowerCase()) ||
            teamLeader.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return work_chunks;
    },
    groups() {
      return this.$store.state.workload["groups"];
    },
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },
  },

  methods: {
    openDelete(task) {
      this.deleteTask.task = task;
      this.deleteTask.dialog = true;
    },

    resetDelete() {
      this.deleteTask.dialog = false;
      this.deleteTask.task = {};
      this.deleteTask.loading = false;
    },

    saveDelete() {
      this.deleteTask.loading = true;

      this.$store
        .dispatch("workload/archiveWorkload", {
          appId: this.$route.params.id,
          id: this.deleteTask.task.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteTask.loading = false;
        });
    },
  },
};
</script>
