var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Activity")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Activity","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.work_chunks,"no-data-text":"No work chunks found"},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'module-workload-individual',
                params: { chunkId: item.id },
              }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.team_member",fn:function({ item }){return [(item.team_member)?_c('div',[_vm._v(" "+_vm._s(item.team_member.first_name)+" "+_vm._s(item.team_member.last_name)+" ")]):_c('div',{staticStyle:{"color":"#cccccc"}},[_vm._v("VITAL HIKE")])]}},{key:"item.customer",fn:function({ item }){return [(item.customer)?_c('router-link',{attrs:{"to":{
                name: 'customer-profile-basic',
                params: { customerId: item.customer.id },
              }}},[_vm._v(_vm._s(item.customer.name))]):_vm._e(),(item.is_internal == 1)?_c('div',{staticStyle:{"color":"#cccccc"}},[_vm._v(" VITAL HIKE ")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"red lighten-4 red--text"},on:{"click":function($event){return _vm.openDelete(item)}}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Archive")])])]}}],null,true)},[_c('span',[_vm._v("Archive")])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.deleteTask.dialog),callback:function ($$v) {_vm.$set(_vm.deleteTask, "dialog", $$v)},expression:"deleteTask.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Archive Activity")]),_c('v-card-text',[_vm._v("Are you sure you wish to archive "+_vm._s(_vm.deleteTask.task.title)+"?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","depressed":""},on:{"click":_vm.resetDelete}},[_vm._v("No, cancel")]),_c('v-btn',{attrs:{"color":"success","depressed":"","loading":_vm.deleteTask.loading},on:{"click":_vm.saveDelete}},[_vm._v("Yes")])],1)],1)],1),_c('WorkChunkForm',{ref:"workChunkForm",attrs:{"groups":_vm.groups,"customers":_vm.customers,"teamMembers":_vm.teamMembers}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }